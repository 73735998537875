interface Array<T> {
  getDistinctArray(): T[];
  getDistinctByFieldArray(field: string): T[];
}

/**
 * @description Фильтрует массив, оставляя только уникальные вхождения
 *
 * @example: [1,1,2,2,3,4,5].getDistinctArray();
 * @output: [1,2,3,4,5]
 */
if (!Array.prototype.getDistinctArray) {
  Array.prototype.getDistinctArray = function <T>() {
    return [...new Set(this)];
  };
}

/**
 * @description Фильтрует массив, оставляя только уникальные вхождения объектов по заданному полю
 *
 * @param field - поле в объекте по которому делаем объекты уникальными
 *
 * @example:
 * [
 *  { id: 1, name: 'abc' },
 *  { id: 1, name: 'bac' },
 *  { id: 2, name: 'cab' },
 * ].getDistinctByFieldArray('id');
 * @output:
 * [
 *  { id: 1, name: 'abc' },
 *  { id: 2, name: 'cab' },
 * ];
 */
if (!Array.prototype.getDistinctByFieldArray) {
  Array.prototype.getDistinctByFieldArray = function <T>(field: string) {
    return [...new Map(this.map((item: T) => [item[field], item])).values()];
  };
}
